import React, { Component } from "react";
import './home.scss';
import Footer from "./../../components/footer/";
import Hero from "./../../components/hero/";
import Bumper from "./../../components/bumper/";
import OverlapScreen from "./../../components/overlapScreen/";
import Features, { FeatureDisplay } from "./../../components/features/";
import { getAppUrl } from "../../utils/ops";
import {TestimonialsWrapper, Testimonials} from "../../components/testimonials"
import { useIntercom } from "react-use-intercom";

function Home() {
	const {show, showNewMessage} = useIntercom()

	return (
		<div className="home-page">
			<Hero
				icon={"./images/glyph-soar-white.svg"}
				backgroundImage={"./images/hero-background.jpg"}
				// text="The Future Of Aviation Safety Powered By AI"
				text="Revolutionize Safety For Your Charter Business"
				//highLightWords={["Safety,",'Aviation','Ai']}
				buttonText="Join waitlist"
				onButtonClick={() => {showNewMessage()}}
				buttonSubtext=""
			 />
			<OverlapScreen />
			<section className="feature-list">
				<FeatureDisplay 
					imageUrl="/images/preflight_decision_making.png"
					header="Improve Preflight Decision Making"
					description="Empower pilots to make informed decisions with real-time, data-driven risk assessments for informed go/no-go decisions on every flight."
				/>

				<FeatureDisplay 
					imageUrl="/images/flight_dept_oversight.png"
					header="Enhance Flight Department Oversight"
					description="Identify high-risk flights proactively, track all mitigation actions taken, and clear flights for takeoff after effective mitigation."
					reverse
				/>

				<FeatureDisplay 
					imageUrl="/images/operational-efficiency.png"
					header="Boost Operational Efficiency"
					addChrome
					description="Save time across your operation with seamless integrations into leading planning, scheduling, and communication platforms."
				/>

				<FeatureDisplay 
					imageUrl="/images/safety_culture_20240812.png"
					header="Strengthen Your Safety Culture"
					description="Empower your flight department to submit feedback confidentially, track actions taken, and ensure continuous improvement."
					addChrome
					reverse
				/>

				<FeatureDisplay 
					imageUrl="/images/safety-insights-20240812.png"
					header="Gain Safety Insights"
					addChrome
					description="Get ahead of potential issues with proactive safety insights that provide a comprehensive overview of safety performance across your organization."
				/>

			</section>


			{/*
			<FeatureDisplay 
				imageUrl="/images/map-20240125.png"
				header="Say goodbye to cumbersome risk assessments"
				description="Explore a new approach to pre-flight risk assessments, prioritizing crucial information tailored to you and your aircraft."
			/>
			*/}
			<Testimonials 
				title="Trusted by Operators"
			/>

			<Bumper
				icon={"./images/glyph-soar-white.svg"}
				title="Discover a new approach to safety management"
				//highLightWords={['Be','a','Part','of','It']}
				//descripton={`Secure your spot for a first-hand experience with next-gen web automation. Don't get left behind.`}
				//
				buttonText="Join waitlist"
				buttonLink={getAppUrl("/register")}
				onButtonClick={() => {showNewMessage()}}
			/>
			<Footer />
		</div>
	);
}

export default Home;
